import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  postRequest,
  getRequest,
  binarySearch,
} from "lingoflix-shared/src/utils.js";
import BackButton from "../atoms/BackButton.js";
import Scene from "./Scene.js";
import styled from "styled-components";
import { ProgressContext } from "../ContextProvider.js";
import NumberIcon from "../NumberIcon.js";
import { isIOS } from "react-device-detect";
import IconCompress from "../../assets/icons/compress.svg";
import IconExpand from "../../assets/icons/expand.svg";

import IconSquare from "../../assets/icons/square.svg";
import IconSquareCheck from "../../assets/icons/square-check.svg";

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 44px;
  cursor: pointer;
  pointer-events: none;
`;

const TopButtonsShow = styled.div`
  display: flex;
  position: absolute;
  left: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(2rem + 18px + 4rem)"
      : "0"}; // 18px BackButton width & 4rem FullscreenButton width
  width: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(100% - 2rem - 18px - 4rem - 4rem - 58px)"
      : "100%"};
  min-height: calc(2rem + 44px);
  z-index: 2;
  cursor: pointer;
`;

const FullscreenButton = styled.button`
  &.fullscreen-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    outline: none;
  }
`;

const TopBarButtonWrapper = styled.div`
  padding: 1rem;
`;

function parseTimeLiberally(time) {
  if (!time) return false;

  let nonMicrosStr = "";
  let micros = 0;
  if (time.indexOf(".") != -1) {
    let dotSplit = time.split(".");
    if (dotSplit.length != 2) {
      return false;
    }
    nonMicrosStr = dotSplit[0];
    micros = parseInt(dotSplit[1].padEnd(6, "0"));
    if (micros === null) {
      return false;
    }
  } else {
    nonMicrosStr = time;
    micros = 0;
  }

  var components = nonMicrosStr
    .split(":")
    .map((x) => parseInt(x, 10))
    .reverse();
  var seconds = 0;
  for (let i = 0; i < components.length; i++) {
    if (components[i] === null) {
      return false;
    }
    seconds += components[i] * Math.pow(60, i);
  }

  return seconds * 1000000 + micros;
}

function getInt(numberString) {
  if (typeof numberString !== "string" || numberString.includes("_")) {
    return -1;
  }
  try {
    return parseInt(numberString, 10);
  } catch (e) {
    return -1;
  }
}

function FadeIn({ children }) {
  const divRef = useRef(null);
  setTimeout(() => {
    divRef.current.style.opacity = 1;
  }, 10);
  return (
    <div
      style={{ opacity: 0, transition: "opacity 0.5s ease-in-out" }}
      ref={divRef}
    >
      {children}
    </div>
  );
}

function getSceneBoundaries(subtitles, fragments) {
  const subBoundaries = [
    { start: 0, end: subtitles[0].start },
    ...subtitles.map((sub) => ({
      start: sub.start,
      end: sub.end,
    })),
    {
      start: subtitles[subtitles.length - 1].end,
      end: Math.ceil(subtitles[subtitles.length - 1].end * 1.05),
      // Note: in theory the breakdown ought to record how long any final
      // credits scene is, but right now it doesn't, so for now we assume it's 5%
      // of the length of the movie up to the last subtitle.
    },
  ];

  const sceneBoundaries = fragments.map((fragment, fragmentIndex) => {
    if (fragment.includes("_")) {
      const [left, right] = fragment.split("_");
      return {
        index: fragmentIndex,
        fragment: fragment,
        start: subBoundaries[parseInt(left)].end,
        end: subBoundaries[parseInt(right)].start,
      };
    }
    return {
      ...subBoundaries[parseInt(fragment)],
      index: fragmentIndex,
      fragment: fragment,
    };
  });
  return sceneBoundaries;
}

// This is the size threshold for a video to be streamed rather than pre-fetched.
const VIDEO_SIZE_STREAMING_THRESHOLD = 1_000_000; // 1MB

// This is how many scenes we pre-fetch video for on either side of the active
// scene (if the video is small enough to require that)
const VIDEOS_FETCHED_ON_EITHER_SIDE = 5;

// This is how many scenes we render on either side of the active scene
// (increasing this number will make the multi-swiper less responsive, but
// picking 0 would make the adjacent scenes look black until the swipe completes)
const SCENES_RENDERED_ON_EITHER_SIDE = 1;

// Due to constraints in iOS safari we need to reuse video elements rather than
// recreating new ones.  These constants are the number of video elements we
// render on either side of the active scene.
const NUM_VIDEO_ELEMENTS_BEFORE = 1;
const NUM_VIDEO_ELEMENTS_AFTER = 1;
const NUM_VIDEO_ELEMENTS =
  1 + NUM_VIDEO_ELEMENTS_BEFORE + NUM_VIDEO_ELEMENTS_AFTER;

export default function MultiSwiper({
  config,
  episode,
  fragments,
  videoSizes,
  posterUrls,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  indicateReady,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [sceneToCardCount, setSceneToCardCount] = useState(null);
  const fetchingSceneToCardCount = useRef(false);

  const fragmentsReversed = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(fragments).map(([idx, frag]) => [frag, +idx]),
      ),
    [fragments],
  );

  const initialLoad = useRef(true);
  const mainSwiper = useRef(null);
  const videoElementsRef = useRef([]);

  useEffect(() => {
    videoElementsRef.current = Array(NUM_VIDEO_ELEMENTS)
      .fill(null)
      .map(() => document.createElement("video"));
  }, []);

  const [progress, setProgress] = useContext(ProgressContext);

  // The active scene is whichever slide of the slider was the last one to be
  // the only one in screen. This is *not* the same as `mainSwiper.current.activeIndex`
  // because `mainSwiper.current.activeIndex` changes in the middle of a swipe,
  // and if we do an expensive rerender in the middle of a swipe, it can cause
  // the animation to hiccup in the middle of the swipe.
  const [activeScene, setActiveScene] = useState(null);

  const [subtitlesVisible, setSubtitlesVisible] = useState(true);
  const [popoverState, setPopoverState] = useState(null);
  const [popoverHasScrollbars, setPopoverHasScrollbars] = useState(false);
  const [popupState, setPopupState] = useState(null);
  const [isVisibleTopButtons, setIsVisibleTopButtons] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [isSeekBarVisible, setIsSeekBarVisible] = useState(false);

  const [activeBreakdown, setActiveBreakdown] = useState(config["default"]);

  const possibleBreakdowns = useMemo(
    () => Object.keys(config["breakdowns"]),
    [config],
  );
  const subtitles = useMemo(
    () => config.breakdowns[activeBreakdown],
    [config, activeBreakdown],
  );
  const sceneBoundaries = useMemo(
    () => getSceneBoundaries(subtitles, fragments),
    [subtitles, fragments],
  );

  const getSubtitle = useCallback(
    (scene) => {
      const defaultSubtitle = { start_time: "00:00:00.000000" };
      if (scene === null) return defaultSubtitle;
      const subIndex = getInt(fragments[scene]) - 1;
      return subIndex >= 0 && subIndex < subtitles.length
        ? subtitles[subIndex]
        : subIndex === -1
        ? defaultSubtitle
        : subtitles[getInt(fragments[scene - 1]) - 1]
        ? { start_time: subtitles[getInt(fragments[scene - 1]) - 1].end_time }
        : defaultSubtitle;
    },
    [fragments, subtitles],
  );

  useEffect(() => {
    if (fragments && !fetchingSceneToCardCount.current) {
      fetchingSceneToCardCount.current = true;
      getRequest(
        "/api/anki/episode_cards_nr_by_scene/" + episode.id,
        {},
        (data) => {
          setSceneToCardCount(
            fragments.map((fragment) => {
              const breakdownIndex = getInt(fragment) - 1;
              return breakdownIndex >= 0 ? data[breakdownIndex] : 0;
            }),
          );
        },
      );
    }
  }, [episode, fragments]);

  const toggleVisibility = () => {
    setIsVisibleTopButtons(!isVisibleTopButtons);
  };
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // for Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // for Safari
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // for IE
        document.documentElement.msRequestFullscreen();
      } else {
        setIsFullscreen(false);
      }
    } else {
      setIsFullscreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // for Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // for Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // for IE
        document.msExitFullscreen();
      } else {
        setIsFullscreen(true);
      }
    }
  };

  function createCardCallback(scene) {
    setSceneToCardCount((current) => {
      if (current === null) return null;
      const newCount = current.slice();
      newCount[scene] = (newCount[scene] ?? 0) + 1;
      return newCount;
    });
  }

  const getDisplacementInfo = useCallback(() => {
    const swiper = mainSwiper.current;
    if (!swiper) return null;
    const activeScene = swiper?.activeIndex ?? null;
    if (activeScene === null) return null;
    const displacementPixels =
      -swiper.slidesGrid[activeScene] - swiper.translate;
    const displacement = displacementPixels / swiper.width;
    const displacementSign = Math.sign(displacement);
    let visibleScenes = [activeScene];
    if (displacementSign !== 0) {
      visibleScenes.push(activeScene + displacementSign);
    }
    return {
      activeScene,
      displacement,
      visibleScenes,
    };
  }, [mainSwiper]);

  const updateProgress = useCallback(() => {
    if (mainSwiper.current === null) return;
    setProgress((progress) => ({
      ...progress,
      [episode.id]: mainSwiper.current.progress,
    }));
    postRequest("/api/progress", {
      episodeId: episode.id,
      progress: mainSwiper.current.progress,
    });
  }, [mainSwiper, setProgress, episode]);

  const updateActiveScene = useCallback(
    (scene) => {
      setActiveScene(scene);
      // Update the page title:
      let titleComponents = [episode.title, episode.show.title];
      const subtitle = getSubtitle(scene);
      if (scene) {
        titleComponents.unshift(
          subtitle.start_time.split(".")[0].replace(/^0:/, ""),
        );
      }
      document.title = titleComponents.join(" - ");
      // Update the URL:`
      if (subtitle.start_time) {
        window.history.replaceState(
          {},
          "", // See: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
          `/watch/${episode.show.url}/${episode.url}?time=${subtitle.start_time}`,
        );
      }
    },
    [episode, getSubtitle],
  );

  const updateVisibleScenes = useCallback(() => {
    if (mainSwiper.current === null) return;
    const result = getDisplacementInfo();
    if (result === null) return;
    const { visibleScenes } = result;
    if (visibleScenes.length === 1) {
      setPopupState(null);
      updateActiveScene(visibleScenes[0]);
      updateProgress();
    }
  }, [getDisplacementInfo, updateActiveScene, updateProgress]);

  useEffect(() => {
    updateVisibleScenes();
  }, [updateVisibleScenes, episode, mainSwiper]);

  function handleBackButton() {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`/browse/${episode.show.url}`);
    }
  }

  // Hide top buttons after 2.5 seconds
  useEffect(() => {
    let timer;
    if (isVisibleTopButtons) {
      timer = setTimeout(() => setIsVisibleTopButtons(false), 2500);
    }

    return () => clearTimeout(timer);
  }, [isVisibleTopButtons]);

  // Set scene on page load based on URL or progress
  useEffect(() => {
    if (mainSwiper.current && initialLoad.current) {
      initialLoad.current = false;
      const startTime = searchParams.get("time");
      const startTimeMicros = parseTimeLiberally(startTime);
      const fragment =
        startTimeMicros !== false &&
        fragments[
          Math.max(
            0,
            binarySearch(sceneBoundaries, startTimeMicros, (x) => x.start).low,
          )
        ];
      const startScene =
        fragment && fragment in fragmentsReversed
          ? fragmentsReversed[fragment]
          : -1;
      // If we have progress for this episode, use it to set the scene.
      const hasProgress =
        progress &&
        episode.id in progress &&
        progress[episode.id] !== null &&
        !isNaN(progress[episode.id]);
      const newScene =
        startScene !== -1
          ? startScene
          : hasProgress
          ? Math.round(progress[episode.id] * fragments.length)
          : 0;
      if (newScene >= 0) {
        updateActiveScene(newScene);
        mainSwiper.current.slideTo(newScene);
      }
    }
  }, [progress]);

  const breakdownSwitchOptions = useMemo(() => {
    return possibleBreakdowns.length > 1
      ? possibleBreakdowns.map((name) => ({
          title: name,
          label: (
            <span>
              <img
                src={name === activeBreakdown ? IconSquareCheck : IconSquare}
                width="16"
                height="16"
              />{" "}
              {name}
            </span>
          ),
          action: () => setActiveBreakdown(name),
        }))
      : [];
  }, [possibleBreakdowns, activeBreakdown]);

  const slidePrev = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slidePrev();
    }
  }, [mainSwiper]);

  const slideNext = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slideNext();
    }
  }, [mainSwiper]);

  const slideNextInstantly = useCallback(() => {
    if (mainSwiper.current) {
      mainSwiper.current.slideNext(0);
      // Note: 0 makes us instantly switch slides instead of doing an animation.
    }
  }, [mainSwiper]);

  const seekToScene = useCallback(
    (n) => {
      if (mainSwiper.current) {
        mainSwiper.current.slideTo(n);
      }
    },
    [mainSwiper],
  );

  return (
    <FadeIn>
      {!popupState?.popup && (
        <>
          <TopButtonsShow
            onClick={toggleVisibility}
            $buttonsAreVisible={isVisibleTopButtons}
          />
          <BackButtonWrapper>
            {isVisibleTopButtons && (
              <TopBarButtonWrapper>
                <BackButton onClick={handleBackButton} />
              </TopBarButtonWrapper>
            )}
            {!isIOS && isVisibleTopButtons && (
              <FullscreenButton
                className="fullscreen-button"
                onClick={toggleFullscreen}
              >
                <img
                  src={isFullscreen ? IconCompress : IconExpand}
                  width="30"
                  height="30"
                />
              </FullscreenButton>
            )}
            <div onClick={toggleVisibility} style={{ width: "100%" }}></div>
            {!!sceneToCardCount?.[activeScene] && (
              <TopBarButtonWrapper
                style={{
                  display: isVisibleTopButtons ? "block" : "none",
                }}
              >
                <NumberIcon
                  number={sceneToCardCount?.[activeScene] ?? 0}
                  onClick={() =>
                    setPopupState({
                      popup: "deck",
                      breakdown: getSubtitle(activeScene),
                    })
                  }
                  style={{ padding: "1rem" }}
                  icon={"cards"}
                />
              </TopBarButtonWrapper>
            )}
            {!isVisibleTopButtons && (
              <div
                style={{
                  cursor: "pointer",
                  pointerEvents: "none",
                  width: "calc(5% + 4.5rem + 6px)",
                }}
              ></div>
            )}
          </BackButtonWrapper>
        </>
      )}
      <Swiper
        direction="horizontal"
        loop={false}
        spaceBetween={30}
        centeredSlides
        keyboard={{ enabled: false }}
        threshold={50}
        onSwiper={(swiper) => {
          mainSwiper.current = swiper;
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
        onSlideChangeTransitionEnd={updateVisibleScenes}
      >
        {fragments.map((f, i) => (
          <SwiperSlide key={i} virtualIndex={i}>
            {Math.abs(i - activeScene) <= VIDEOS_FETCHED_ON_EITHER_SIDE && (
              <Scene
                render={
                  Math.abs(i - activeScene) <= SCENES_RENDERED_ON_EITHER_SIDE
                }
                isStreaming={
                  videoSizes?.video_sizes?.[i] > VIDEO_SIZE_STREAMING_THRESHOLD
                }
                videoElement={videoElementsRef.current[i % NUM_VIDEO_ELEMENTS]}
                fragment={f}
                subtitle={getSubtitle(i)}
                episode={episode}
                sceneBoundaries={sceneBoundaries}
                index={i}
                posterUrls={posterUrls}
                shouldClaimVideoElement={
                  i >= activeScene - NUM_VIDEO_ELEMENTS_BEFORE &&
                  i <= activeScene + NUM_VIDEO_ELEMENTS_AFTER
                }
                isActiveScene={i === activeScene}
                popoverState={popoverState}
                setPopoverState={setPopoverState}
                popupState={popupState}
                popoverHasScrollbars={popoverHasScrollbars}
                setPopoverHasScrollbars={setPopoverHasScrollbars}
                setPopupState={setPopupState}
                subtitlesVisible={subtitlesVisible}
                setSubtitlesVisible={setSubtitlesVisible}
                isSeekBarVisible={isSeekBarVisible}
                setIsSeekBarVisible={setIsSeekBarVisible}
                createCardCallback={createCardCallback}
                breakdownMenuOptions={breakdownSwitchOptions}
                getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
                setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
                slidePrev={slidePrev}
                slideNext={slideNext}
                slideNextInstantly={slideNextInstantly}
                seekToScene={seekToScene}
                indicateReady={indicateReady}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </FadeIn>
  );
}
