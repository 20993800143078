import React, { useState, useEffect, useCallback } from "react";
import {
  timeMicrosOf,
  postRequest,
  filterKanji,
  getRequest,
  findCharacterSubstring,
} from "lingoflix-shared/src/utils.js";
import { toast } from "react-toastify";
import CardIcon from "../../../assets/icons/cards.svg";
import AnkiForm from "./AnkiForm.js";
import Loading from "../../Loading.js";

const CARD_FRONT_MAX_DEFINITIONS = 3;

export function makeAnkiCardData(data) {
  return {
    cardFront: data.cardFront,
    cardBack: data.cardBack,
    word: {
      spans: [{ text: data.original, romaji: data.romaji }],
    },
    sentence: {
      jmdMeaning: data.sentenceTranslation,
      spans: data.sentenceSpans
        ? data.sentenceSpans.map((s) =>
            s.map((e) => ({
              original: e.original,
              pronunciation: e.pronunciation,
            })),
          )
        : [],
    },
    eng_word: data.translation,
    eng_fullsub: data.sentenceTranslation,
    video_frame_src: data.videoFrameSrc,
    custom_fields: (data.customFields || []).map((f) => ({
      title: f.title,
      content: f.content,
    })),
    rubyBreakdown: data.rubyBreakdown,
    wordRubyBreakdown: data.wordRubyBreakdown,
    kanjis: filterKanji(data.characters).map((k) => ({
      kanji: k.original,
      english: k.translations[0],
    })),
    jmdictId: data.jmdictId,
    jmdictText: data.jmdictText,
    jmdictRubyBreakdown: data.jmdictRubyBreakdown,
    jmdictWordRank: data.jmdictWordRank,
    wordRank: data.wordRank,
  };
}

function rotateToFirst(list, index) {
  if (index !== 0) {
    return [list[index], ...list.slice(0, index), ...list.slice(index + 1)];
  }
  return list;
}

export function createAnkiCard(
  parentBreakdown,
  childBreakdown,
  data,
  posterPath,
  callback,
) {
  data.videoFrameSrc = posterPath;
  postRequest(
    "/api/anki/new_card",
    {
      data: makeAnkiCardData({
        ...data,
        characters: childBreakdown?.characters,
      }),
      episodeId: data.episodeId,
      timeMicros: timeMicrosOf(parentBreakdown),
      breakdownIndex: parentBreakdown.index,
      wordIndex:
        typeof childBreakdown?.index === "number"
          ? childBreakdown.index
          : undefined,
      sentence: parentBreakdown.original,
      word: childBreakdown?.original ? childBreakdown.original : undefined,
    },
    callback,
  );
}

function getRubyBreakdown(breakdown, original) {
  // Computes the ruby characters for a word.
  // parentBreakdown is assumed to have a `characters` element.  We naively
  // search every substring of characters for the original word to select the
  // subset of characters that need to be included in the ruby breakdown.
  const characters = breakdown?.characters || [];
  const usedCharacters = findCharacterSubstring(characters, original);

  if (usedCharacters === null) {
    const errorMessage = `Error: Could not find ruby characters for ${original} in ${characters
      .map((x) => x.original)
      .join("")}`;
    toast(errorMessage);
    // Fall back to no ruby breakdown.
    if (!original) {
      return [];
    }
    return original.split("").map((c) => ({ original: c }));
  }
  if (usedCharacters.every((c) => c.romaji !== undefined)) {
    return usedCharacters.map((c) => ({
      original: c.original,
      romaji: c.pronunciation,
      kana: c.kana,
    }));
  } else if (original == breakdown.original) {
    // If it's the entire word and the character level breakdown doesn't work,
    // we make a trivial ruby breakdown.
    return [
      {
        original: breakdown.original,
        romaji: breakdown.pronunciation,
        kana: breakdown.kana,
      },
    ];
  } else {
    console.warn(
      `Could not construct ruby breakdown for ${original} in ${breakdown.original}`,
    );
    return [{ original: original, romaji: "", kana: "" }];
  }
}

export default function AnkiCreator({
  videoPath,
  posterPath,
  posterBlobUrl,
  episode,
  parentBreakdown,
  childBreakdown,
  morpheme,
  closeCallback,
  createCallback,
  setWordBreakdownKnowledgeState,
}) {
  const [loadingAnkiDraft, setLoadingAnkiDraft] = useState(true);
  const [loadingJmdict, setLoadingJmdict] = useState(true);
  const [loadingWordAudioPath, setLoadingWordAudioPath] = useState(true);
  const [ankiDraft, setAnkiDraft] = useState(null);
  const [jmdictEntry, setJmdictEntry] = useState(null);
  const [wordAudioPath, setWordAudioPath] = useState(null);

  const setKnown = useCallback(
    (data) => {
      const breakdown = { jmdict_id: data.jmdictId, original: data.original };
      // We first make sure we delete any manual known entry.
      setWordBreakdownKnowledgeState(breakdown, {
        knowledgeType: "manual",
        known: false,
      });
      // Then we make sure the card is known:
      setWordBreakdownKnowledgeState(breakdown, {
        knowledgeType: "card",
        known: true,
      });
    },
    [setWordBreakdownKnowledgeState],
  );

  const safeLength = (list) => list?.length || 0;
  const safeFirst = (x) => (safeLength(x) > 0 ? x[0] : "");
  const target = morpheme ? morpheme : childBreakdown;
  const jmdictBreakdown = target?.jmdict;
  const jmdictId = jmdictBreakdown?.jmdict_id;

  const sentenceIndices = parentBreakdown?.words
    .flatMap((c) => c.sentences)
    .filter((s, i, a) => a.indexOf(s) === i);

  useEffect(() => {
    const fetchJmdictEntry = async () => {
      if (!jmdictId) {
        setLoadingJmdict(false);
        return;
      }
      try {
        const entry = await getRequest("/api/jmdict/lookup", {
          jmdictId,
        });
        setJmdictEntry(entry);
        setLoadingJmdict(false);
      } catch (error) {
        console.error("Could not fetch JMDict entry:", error);
        setLoadingJmdict(false);
      }
    };

    fetchJmdictEntry();
  }, [jmdictId, childBreakdown, morpheme]);

  useEffect(() => {
    setLoadingWordAudioPath(true);
    const fetchWordAudio = async () => {
      try {
        const result = await getRequest("/api/jmdict/word_audio_url", {
          jmdictId,
          word: childBreakdown?.original,
        });
        setWordAudioPath(result.audioPath);
      } catch (e) {
        console.err(e);
      } finally {
        setLoadingWordAudioPath(false);
      }
    };
    fetchWordAudio();
  }, [jmdictId, childBreakdown]);

  // Note: Morphemes don't have pronunciations saved in the breakdown so we have
  // to compute them from the pronunciation of the characters of the word they
  // belong to.
  const pronunciation = morpheme
    ? (
        findCharacterSubstring(
          childBreakdown?.characters,
          morpheme?.original,
        ) || []
      )
        .map((c) => c.pronunciation)
        .join("")
    : childBreakdown?.pronunciation;

  // Create a memo for the kanjis
  const kanjis = React.useMemo(() => {
    return filterKanji(childBreakdown?.characters).map((k) => ({
      kanji: k.original,
      english: k.translations[0],
    }));
  }, [childBreakdown?.characters]);

  useEffect(() => {
    const fetchAnkiDraft = async () => {
      try {
        await postRequest(
          "/api/anki-draft/",
          {
            word: childBreakdown?.original,
            sentence: parentBreakdown?.original,
            morpheme: morpheme?.original || "",
          },
          (data) => {
            setAnkiDraft(data);
            setLoadingAnkiDraft(false);
          },
        );
      } catch (error) {
        console.error("Error fetching Anki draft:", error);
        toast.error("Failed to fetch Anki draft. Using default values.");
        setLoadingAnkiDraft(false);
      }
    };

    fetchAnkiDraft();
  }, [target?.original, parentBreakdown?.original]);

  if (loadingAnkiDraft || loadingJmdict || loadingWordAudioPath) {
    return <Loading />;
  }

  const jmdictEntryToCardFront = (jmdictEntry) => {
    const lines = jmdictEntry.senses.map((sense, sense_index) => {
      if (sense_index === CARD_FRONT_MAX_DEFINITIONS) {
        return "etc.";
      } else if (sense_index > CARD_FRONT_MAX_DEFINITIONS) {
        return null;
      }

      const glosses = sense.SenseGloss;
      const englishGlosses = glosses.filter(
        (g) => g.lang === "eng" || g.selected,
      );

      return (
        `${sense_index + 1}. ` +
        englishGlosses
          .map((x) => (x.selected ? `**${x.text}**` : x.text))
          .join(", ")
      );
    });

    return lines.filter(Boolean).join("\n").trim();
  };
  const makeJmdictCardFront = () => {
    if (!jmdictBreakdown || !jmdictId) {
      return null;
    }
    if (!jmdictEntry) {
      console.error(`JMDict entry not found for ${jmdictId}`);
      return null;
    }
    const sense_index = jmdictBreakdown.jmdict_sense_index;
    const gloss_index = jmdictBreakdown.jmdict_gloss_index;
    const translation = jmdictBreakdown?.translations?.[0];

    if (
      sense_index === undefined ||
      gloss_index === undefined ||
      translation === undefined
    ) {
      console.error(
        `Sense index, gloss index, or translation not found for JMDict entry ${jmdictId}`,
      );
      return jmdictEntryToCardFront(jmdictEntry);
    }
    if (sense_index >= safeLength(jmdictEntry.senses)) {
      console.error(
        `Sense index ${sense_index} is out of bounds for JMDict entry ${jmdictId}`,
      );
      return jmdictEntryToCardFront(jmdictEntry);
    }
    if (gloss_index >= safeLength(jmdictEntry.senses[sense_index].SenseGloss)) {
      console.error(
        `Gloss index ${gloss_index} is out of bounds for JMDict entry ${jmdictId}`,
      );
      return jmdictEntryToCardFront(jmdictEntry);
    }
    if (
      jmdictEntry.senses[sense_index].SenseGloss[gloss_index].text !==
      translation
    ) {
      console.error(
        `Translation ${translation} does not match JMDict entry ${jmdictId}`,
      );
      return jmdictEntryToCardFront(jmdictEntry);
    }

    // The selection was valid, so we can rotate it into first position, and
    // mark it as selected.
    jmdictEntry.senses = rotateToFirst(jmdictEntry.senses, sense_index);
    jmdictEntry.senses[0].SenseGloss = rotateToFirst(
      jmdictEntry.senses[0].SenseGloss,
      gloss_index,
    );
    jmdictEntry.senses[0].SenseGloss[0].selected = true;
    return jmdictEntryToCardFront(jmdictEntry);
  };

  const sentenceElements = sentenceIndices.map((i) =>
    (parentBreakdown?.words ?? []).filter((c) => c.sentences.includes(i)),
  );

  const args = {
    videoPath: videoPath,
    wordAudioPath: wordAudioPath,
    posterPath: posterPath,
    posterBlobUrl: posterBlobUrl,
    episode: episode,
    isMorpheme: !!morpheme,
    original: target?.original,
    romaji: pronunciation,
    translations: target?.translations || childBreakdown?.translations || [],
    rubyBreakdown: getRubyBreakdown(childBreakdown, target?.original),
    jmdictId: target?.jmdict?.jmdict_id,
    jmdictText: target?.jmdict?.original,
    jmdictRubyBreakdown: target?.jmdict
      ? getRubyBreakdown(target?.jmdict, target?.jmdict?.original)
      : [],
    jmdictWordRank: target?.jmdict?.wordrank,
    wordRank: target?.wordrank,
    wordRubyBreakdown: getRubyBreakdown(
      childBreakdown,
      childBreakdown?.original,
    ),
    morphemeOriginal: morpheme?.original,
    morphemeTranslation: safeFirst(morpheme?.translations),
    wordOriginal: childBreakdown?.original,
    wordPronunciation: childBreakdown?.pronunciation,
    wordTranslation: safeFirst(childBreakdown?.translations),
    sentenceOriginal: parentBreakdown?.original,
    sentenceTranslation: safeFirst(parentBreakdown?.translations),
    lineSelection: childBreakdown?.sentences[0] || 0,
    sentence:
      parentBreakdown?.original.split("\n")[childBreakdown?.sentences[0] || 0],
    cardFront:
      ankiDraft?.front ??
      makeJmdictCardFront() ??
      morpheme?.anki?.front ??
      childBreakdown?.translations[0],
    cardBack: ankiDraft?.back ?? morpheme?.anki?.back,
    sentenceElements: sentenceElements,
    childSpan: [
      { text: childBreakdown?.original, romaji: childBreakdown?.pronunciation },
    ],
    cardCustomFields: ankiDraft?.customFields || [],
    cardFieldIndex: 1,
    closeCallback: closeCallback,
    submitCallback: (data) => {
      createAnkiCard(parentBreakdown, childBreakdown, data, posterPath, () => {
        setKnown(data);
        createCallback();
        closeCallback();
      });
      toast(
        <>
          <img src={CardIcon} width="16" height="16" /> Anki card created!
        </>,
      );
    },
    autoGenerateMnemonic: true,
    kanjis: kanjis,
  };

  return <AnkiForm {...args} />;
}
