import React, { useRef, useEffect, useState, useCallback } from "react";
import Popup from "./Popup.js";
import IconBlueLink from "../../assets/icons/blue-link.svg";
import BreakdownButton from "../atoms/BreakdownButton.js";

const TallIFrame = ({ src }) => {
  const iframeRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const setLoaded = useCallback(() => setIsLoaded(true), []);

  const computeHeight = () => {
    // Note: the 0.9 is there because the enclosing `Card` component has a
    // max-height of 90%.  The 100px is a somewhat arbitrary overestimation of
    // the height of the JPDB popups's header and the card's vertical
    // margin/padding.
    const height = window.innerHeight * 0.9 - 100;
    return `${height}px`;
  };
  const [height, setHeight] = useState(computeHeight());
  const updateHeight = useCallback(() => setHeight(computeHeight()), []);

  useEffect(() => {
    // Initial height update
    updateHeight();

    // Add resize listener to handle dynamic content
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [updateHeight]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "jpdb-link-click") {
        window.open(
          "https://jpdb.io" + event.data.href,
          "_blank",
          "noreferrer",
        );
      }
    };
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      onLoad={setLoaded}
      src={src}
      style={{
        width: "100%",
        height,
        transition:
          "height 0.3s ease-in-out; width 0.3s ease-in-out; opacity 0.1s ease-in-out",
        border: "none",
        opacity: isLoaded ? 1 : 0,
      }}
    />
  );
};

export default function JpdbPopup({ jmdictId, word, closePopups }) {
  const encodedWord = encodeURIComponent(word);
  const originalURL = `https://jpdb.io/vocabulary/${jmdictId}/${encodedWord}`;
  const internalURL = `/jpdb/vocabulary?jmdictId=${jmdictId}&word=${encodedWord}`;
  return (
    <Popup
      title={
        <>
          {word}
          <BreakdownButton
            onClick={() => {
              window.open(originalURL, "_blank", "noreferrer");
            }}
            className="breakdown-button"
            $padding="0.125rem 0.5rem"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={IconBlueLink} alt="JPDB" width={20} height={20} />
              JPDB
            </div>
          </BreakdownButton>
        </>
      }
      onClose={closePopups}
    >
      <TallIFrame src={internalURL} />
    </Popup>
  );
}
